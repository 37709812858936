import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiAlertFill,
  RiMenuFill,
  RiAddLine,
  RiSubtractLine,
  RiMore2Fill,
  RiCloseCircleLine,
  RiExternalLinkFill,
} from "react-icons/ri";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  UseDisclosureReturn,
  VStack,
} from "@chakra-ui/react";
import CardsList from "../cards";
import { Link as BrowserLink } from "react-router-dom";
import {
  DECK_DRAWER_CLOSED_WIDTH,
  DECK_DRAWER_OPEN_WIDTH,
  TOP_BAR_HEIGHT,
} from "../config";
import { useDeck } from "../deck";
import DeckBuilderCard from "../DeckBuilderCard";
import { useRef, useState } from "react";
import DeckStatusBadge from "../components/DeckStatusBadge";

type Props = {
  drawerState: UseDisclosureReturn;
  isInDrawer: boolean;
  onClickCard: (id: string) => void;
  top?: number;
};

const DeckBuilderPane = ({
  drawerState,
  isInDrawer,
  onClickCard,
  top = 0,
}: Props) => {
  const {
    deck,
    deckSize,
    addCard,
    removeCard,
    createExport,
    setDeckName,
    saveDeck,
    importFromText,
    status: deckStatus,
    changeStatus,
    touched,
  } = useDeck();
  const exportModalState = useDisclosure({ defaultIsOpen: false });
  const importModalState = useDisclosure({ defaultIsOpen: false });
  const [importModalContents, setImportModalContents] = useState("");
  const [exportModalContents, setExportModalContents] = useState("");
  const exportTextRef = useRef<HTMLTextAreaElement>(null);
  const drawerButtonHoverColor = useColorModeValue("blue.500", "blue.400");
  const borderColor = useColorModeValue("gray.800", "gray.300");
  const bgColor = useColorModeValue("teal.50", "teal.900");

  const handleFocusExportText = () => {
    if (!exportTextRef.current) return;
    const range = document.createRange();
    range.selectNodeContents(exportTextRef.current);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleClickExport = () => {
    setExportModalContents(createExport());
    exportModalState.onOpen();
  };

  const handleClickImport = () => {
    importModalState.onToggle();
  };

  const handleClickPerformImport = () => {
    importFromText(importModalContents);
    setImportModalContents("");
    importModalState.onClose();
  };

  const onClickChangeDeckStatus = (status: number) => {
    changeStatus(status);
  };

  const width = drawerState.isOpen
    ? DECK_DRAWER_OPEN_WIDTH
    : DECK_DRAWER_CLOSED_WIDTH;

  return (
    <Box
      bg={isInDrawer ? undefined : bgColor}
      position={isInDrawer ? "relative" : "fixed"}
      width={isInDrawer ? "100%" : width}
      height={isInDrawer ? "auto" : "calc(100vh - 64px)"}
      top={isInDrawer ? 0 : top}
      right="0"
      borderLeftColor={borderColor}
      borderLeftWidth={isInDrawer ? 0 : "4px"}
      borderLeftStyle="solid"
    >
      <VStack p={4} spacing={2} h="full" align="start" overflowY="auto">
        <HStack w="full" justify="space-between">
          {!isInDrawer && <Heading size="md">Deck Builder</Heading>}
          {deckStatus === "ready" ? (
            <Menu>
              <MenuButton
                as={IconButton}
                variant="ghost"
                aria-label="Deck Builder Menu"
                icon={<Icon as={RiMenuFill} />}
              />
              <MenuList>
                <MenuItem onClick={handleClickExport}>Export</MenuItem>
                <MenuItem onClick={handleClickImport}>Import</MenuItem>
                <MenuItem onClick={saveDeck}>Save Deck</MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <>
              <Spinner size="md" />
            </>
          )}
        </HStack>
        {deck.status >= 0 ? (
          <Menu>
            <MenuButton _hover={{ "& .menu-btn-text": { color: "blue.400" } }}>
              <HStack>
                <DeckStatusBadge status={deck.status} />
                <Text
                  className="menu-btn-text"
                  fontSize="xs"
                  fontStyle="italic"
                >
                  Change
                </Text>
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => onClickChangeDeckStatus(0)}>
                Private
              </MenuItem>
              <MenuItem onClick={() => onClickChangeDeckStatus(2)}>
                Public
              </MenuItem>
              <MenuItem onClick={() => onClickChangeDeckStatus(1)}>
                Unlisted
              </MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <DeckStatusBadge status={deck.status} />
        )}
        {(touched && deck.status >= 0 ? 1 : 0) && (
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle>Unsaved changes</AlertTitle>
          </Alert>
        )}
        <VStack w="full" align="start" spacing={1}>
          <Editable w="full" onChange={setDeckName} value={deck.name}>
            <Tooltip label="Click to edit">
              <EditablePreview
                p={2}
                w="full"
                borderRadius={0}
                borderBottomWidth="2px"
              />
            </Tooltip>
            <EditableInput p={2} borderRadius={0} id="deck-name" />
          </Editable>
          {deck.leader && (
            <>
              <Heading size="sm">Leader</Heading>
              <DeckBuilderCard
                onClickCard={onClickCard}
                quantity={0}
                card={deck.leader}
              />
            </>
          )}
          {deck.main.length >= 1 && (
            <>
              <HStack>
                <Heading size="sm">Cards</Heading>
                <Text>{deckSize()} / 50</Text>
              </HStack>
              <VStack spacing={0} align="start" w="full">
                {deck.main.map(({ card, quantity }) => (
                  <DeckBuilderCard
                    onClickCard={onClickCard}
                    card={card}
                    quantity={quantity}
                  >
                    <Menu size="sm">
                      <MenuButton
                        as={IconButton}
                        variant="ghost"
                        size="sm"
                        aria-label="Add 1 to deck"
                        icon={<Icon as={RiMore2Fill} />}
                      />
                      <MenuList>
                        <MenuItem
                          onClick={() => addCard(card)}
                          icon={<Icon as={RiAddLine} />}
                        >
                          Add 1
                        </MenuItem>
                        <MenuItem
                          onClick={() => removeCard(card)}
                          icon={<Icon as={RiSubtractLine} />}
                        >
                          Remove 1
                        </MenuItem>
                        <MenuItem
                          onClick={() => removeCard(card, quantity)}
                          icon={<Icon as={RiCloseCircleLine} />}
                        >
                          Remove All
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </DeckBuilderCard>
                ))}
              </VStack>
            </>
          )}
        </VStack>
      </VStack>
      <Modal
        size="xl"
        isOpen={exportModalState.isOpen}
        onClose={exportModalState.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Deck</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              ref={exportTextRef}
              onFocus={handleFocusExportText}
              minH="8em"
              maxH="80vh"
              h={`${(deck.main.length + (deck.leader ? 1 : 0)) * 1.2 + 5}em`}
              isReadOnly
              value={exportModalContents}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size="xl"
        isOpen={importModalState.isOpen}
        onClose={importModalState.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Deck</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="start">
              <Text>Paste your deck contents below</Text>

              <Textarea
                h="32"
                value={importModalContents}
                onChange={(e) => setImportModalContents(e.target.value)}
              />
              <Alert status="warning">
                <AlertIcon />
                <Box>
                  <AlertTitle>Warning</AlertTitle>
                  <AlertDescription>
                    Importing will create a new deck and erase any unsaved
                    changes in your current deck
                  </AlertDescription>
                </Box>
              </Alert>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={importModalState.onClose} variant="ghost" mr={3}>
              Cancel
            </Button>
            <Button onClick={handleClickPerformImport}>Import</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeckBuilderPane;
