import React, { useCallback, useEffect, useState } from "react";
import {
  Icon,
  Menu,
  MenuButton,
  Button,
  MenuItem,
  Avatar,
  Spinner,
  MenuList,
  Text,
  MenuGroup,
  IconButton,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  VStack,
  FormControl,
  Input,
  FormLabel,
  Heading,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import supabase from "../db/supabase";
import useAuth from "../db/useAuth";
import { RiLogoutCircleLine } from "react-icons/ri";
import useIsMobile from "../utils/useIsMobile";

type Profile = {
  id: string;
  username: string;
};

type State =
  | {
      status: "loading";
    }
  | {
      status: "done";
      data: Profile;
    }
  | {
      status: "error";
      error: string;
    };

type EditProfileState = "loading" | "done";

const UserMenu = () => {
  const displayToast = useToast();
  const { user: authedUser, uid } = useAuth();
  const [state, setState] = useState<State>({ status: "loading" });
  const [username, setUsername] = useState("");
  const [editProfileState, setEditProfileState] =
    useState<EditProfileState>("done");
  const profileModalState = useDisclosure({
    defaultIsOpen: false,
  });

  const handleClickEditProfile = () => {
    if (state.status === "done") {
      setUsername(state.data.username);
      profileModalState.onOpen();
    }
  };

  const performFetch = useCallback(async () => {
    setState({ status: "loading" });
    if (!uid) {
      setState({ status: "error", error: "Not logged in" });
      return;
    }
    const res = await supabase.from("profile").select("*").eq("id", uid);
    if (res.error) {
      setState({
        status: "error",
        error: "Failed to fetch profile",
      });
    } else {
      if (res.body.length <= 0) {
        setState({
          status: "error",
          error: "No profile?",
        });
      } else {
        setState({
          status: "done",
          data: res.body[0],
        });
      }
    }
  }, [uid]);

  const handleClickSave = async () => {
    setEditProfileState("loading");
    const result = await supabase
      .from("profile")
      .update({ username })
      .eq("id", uid);
    if (result.error) {
      displayToast({
        position: "top",
        title: "Failed to edit profile",
        description: result.error.message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
      setEditProfileState("done");
    } else {
      displayToast({
        position: "top",
        title: "Updated profile",
        status: "success",
        duration: 6000,
        isClosable: true,
      });
      performFetch();
      setEditProfileState("done");
      profileModalState.onClose();
    }
  };

  const isMobile = useIsMobile();

  useEffect(() => {
    performFetch();
  }, [performFetch]);

  if (state.status === "loading") {
    return <Button loadingText="Loading..." isLoading></Button>;
  }
  return (
    <>
      <Menu>
        {isMobile ? (
          <MenuButton
            variant="ghost"
            as={IconButton}
            icon={<Avatar size="xs" />}
          />
        ) : (
          <MenuButton
            variant="ghost"
            as={Button}
            leftIcon={<Avatar size="xs" />}
          >
            {(state.status === "done" && state.data.username) ||
              authedUser?.email ||
              "Signed In"}
          </MenuButton>
        )}
        <MenuList>
          <MenuGroup title={authedUser?.email}>
            <MenuItem onClick={handleClickEditProfile}>
              View Profile & Edit Username
            </MenuItem>
            <MenuItem onClick={() => supabase.auth.signOut()}>
              <Icon mr={2} as={RiLogoutCircleLine} />
              Sign Out
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      <Modal
        size="lg"
        isOpen={profileModalState.isOpen}
        onClose={profileModalState.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          {state.status === "done" && state.data && (
            <>
              <ModalHeader>{state.data.username}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack w="full" align="start" spacing={4}>
                  <Text>{authedUser?.email || "Unknown Email"}</Text>
                  <VStack w="full" align="start">
                    <Heading size="sm">Edit Username</Heading>
                    <FormControl>
                      <FormLabel>Username</FormLabel>
                      <Input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </FormControl>
                  </VStack>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={profileModalState.onClose}
                  variant="ghost"
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={editProfileState === "loading"}
                  loadingText="Saving"
                  onClick={handleClickSave}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserMenu;
