import {
  Badge,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Tooltip,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { RiAddLine, RiSubtractLine, RiInformationFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Card } from "../card";
import cardback from "../cardback.jpg";
import { useDeck } from "../deck";

type Props = {
  card: Card;
  onClick: () => void;
};

const CardItem = ({ card, onClick }: Props) => {
  const { quantityByID, removeCard, addCard } = useDeck();

  const printing = card.printings[0];
  const hasMultiplePrintings = card.printings.length > 1;

  return (
    <LinkBox
      as={VStack}
      transform="scale(0.9)"
      key={card.id}
      borderWidth="2px"
      maxW="180px"
      p={2}
      _hover={{ borderColor: "blue.500", cursor: "pointer" }}
    >
      <Image fallbackSrc={cardback} src={`/cardthumb${printing?.artUrl}`} />
      {hasMultiplePrintings && (
        <Box
          zIndex="1"
          pos="absolute"
          top={2}
          left="100%"
          transform="translate(-50%, -100%)"
        >
          <Tooltip shouldWrapChildren label="Card has alternate printings">
            <Icon boxSize="1.5rem" as={RiInformationFill} color="purple.400" />
          </Tooltip>
        </Box>
      )}
      <HStack w="full" align="center">
        <Badge colorScheme={card.colors.length === 1 ? card.colors[0] : "gray"}>
          {card.id}
        </Badge>
        <LinkOverlay
          onClick={onClick}
          fontSize="sm"
          fontWeight="bold"
          noOfLines={1}
        >
          {card.name}
        </LinkOverlay>
      </HStack>

      {quantityByID(card.id) >= 1 ? (
        <HStack>
          <IconButton
            onClick={() => removeCard(card)}
            variant="ghost"
            aria-label="Remove from deck"
            icon={<Icon as={RiSubtractLine} />}
          />
          <Text>{quantityByID(card.id)}</Text>
          <IconButton
            onClick={() => addCard(card)}
            variant="ghost"
            aria-label="Add to deck"
            icon={<Icon as={RiAddLine} />}
          />
        </HStack>
      ) : (
        <HStack>
          <Button
            onClick={() => addCard(card)}
            variant="ghost"
            leftIcon={<Icon as={RiAddLine} />}
          >
            Add To Deck
          </Button>
        </HStack>
      )}
    </LinkBox>
  );
};
export default CardItem;
