import {
  Badge,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import DeckCard from "../components/DeckCard";
import supabase from "../db/supabase";
import useAuth from "../db/useAuth";
import { OnlineDeck, savedDeckToDeck, useDeck } from "../deck";

type State =
  | {
      status: "loading";
    }
  | {
      status: "done";
      data: OnlineDeck[];
    }
  | {
      status: "error";
      error: string;
    };

const UserDecksScreen = () => {
  const { user: authedUser, uid: authedUserUid } = useAuth();
  const { loadDeck } = useDeck();
  const [modalState, setModalState] = useState<null | OnlineDeck>(null);
  const [userDecks, setUserDecks] = useState<State>({ status: "loading" });

  // Fetch decks
  useEffect(() => {
    const fetch = async () => {
      console.log("fetching user decks");
      setUserDecks({ status: "loading" });
      try {
        const decks = await supabase
          .from("deck")
          .select("*")
          .eq("ownerid", authedUserUid);
        if (decks.error) {
          setUserDecks({ status: "error", error: "Failed to fetch decks" });
        } else {
          setUserDecks({
            status: "done",
            data: decks.data.map(savedDeckToDeck),
          });
        }
      } catch (e) {
        setUserDecks({ status: "error", error: "Failed to fetch decks" });
      }
    };
    if (!authedUserUid) {
      setUserDecks({ status: "error", error: "Not signed in" });
    } else {
      fetch();
    }
  }, [authedUserUid]);

  return (
    <VStack spacing={8} w="full" align="start">
      <Heading>Your Decks</Heading>
      {authedUser === null && (
        <Text>Please create an account or sign in to see your saved decks</Text>
      )}
      {userDecks.status === "loading" && (
        <>
          <Flex flexWrap="wrap">
            <Skeleton m={2} w="64" h="64" />
            <Skeleton m={2} w="64" h="64" />
            <Skeleton m={2} w="64" h="64" />
            <Skeleton m={2} w="64" h="64" />
            <Skeleton m={2} w="64" h="64" />
          </Flex>
        </>
      )}
      {userDecks.status === "done" && (
        <>
          <Flex wrap="wrap">
            {userDecks.data.map((deck) => (
              <DeckCard
                onClick={() => setModalState(deck)}
                key={deck.id}
                deck={deck}
                type="own"
              />
            ))}
          </Flex>
        </>
      )}
      <Modal isOpen={!!modalState} onClose={() => setModalState(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Load this deck into deck editor?</ModalHeader>
          {modalState && (
            <>
              <ModalBody>
                <HStack>
                  <Heading size="sm">{modalState.name}</Heading>
                  <Badge>id {modalState.id.slice(-10)}</Badge>
                </HStack>
                <Text>
                  Any unsaved deck currently in your deck editor will be lost
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  mr={3}
                  onClick={() => setModalState(null)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    loadDeck(modalState, false);
                    setModalState(null);
                  }}
                >
                  Load Deck
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default UserDecksScreen;
