import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Heading,
  HStack,
  chakra,
  Link,
  Text,
  VStack,
  Center,
} from "@chakra-ui/react";
import { BOT_INV, SPREADSHEET_LINK } from "../config";

const AboutScreen = () => {
  return (
    <VStack w="full" align="start" spacing={8}>
      <Heading>About OPCards</Heading>
      <HStack align="start" spacing={4} wrap="wrap">
        <chakra.iframe
          title="discord-invite"
          src="https://discord.com/widget?id=1003519632310800454&theme=dark"
          width="128"
          height="64"
          allowTransparency
          style={{ backgroundColor: "blue" }}
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        ></chakra.iframe>
        <VStack align="start">
          <Text>
            Join the discord server to give feedback, complain about bugs, or to
            keep an eye on new development.
          </Text>
          <Text>
            <Link href={BOT_INV} isExternal>
              Click here
            </Link>{" "}
            to add the OPCards discord bot to your discord server
          </Text>
          <Text>
            OPCards card viewer, deck builder and discord bot are built by
            theLEECH.
          </Text>
          <Text>
            Data is primarily sourced from MetalXLemmy's google spreadsheet,
            which{" "}
            <Link isExternal href={SPREADSHEET_LINK}>
              can be found here.
            </Link>
          </Text>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default AboutScreen;
