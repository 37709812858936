import { ComponentStyleConfig, extendTheme } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 0,
  },
  variants: {
    outline: ({ colorMode }) => ({
      borderColor: colorMode === "light" ? "gray.800" : "gray.300",
      borderWidth: "2px",
      _hover: {
        bgColor: colorMode === "light" ? "gray.800" : "gray.300",
        color: colorMode === "light" ? "gray.100" : "gray.800",
      },
    }),
  },
  defaultProps: {
    variant: "outline",
  },
};

const Input: ComponentStyleConfig = {
  variants: {
    outline: ({ colorMode }) => ({
      field: {
        borderColor: colorMode === "light" ? "gray.800" : "gray.300",
        borderWidth: "2px",
        _hover: {
          borderColor: colorMode === "light" ? "gray.800" : "gray.300",
        },
        _focusVisible: {
          boxShadow: "none",
        },
      },
    }),
  },
  sizes: {
    sm: {
      field: {
        borderRadius: 0,
      },
    },
    md: {
      field: {
        borderRadius: 0,
      },
    },
    lg: {
      field: {
        borderRadius: 0,
      },
    },
  },
};

const Modal: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      borderRadius: 0,
      shadow: "dark-lg",
    },
  },
};

const Select: ComponentStyleConfig = {
  variants: {
    outline: ({ colorMode }) => ({
      field: {
        borderColor: colorMode === "light" ? "gray.800" : "gray.300",
        borderWidth: "2px",
        _hover: {
          borderColor: colorMode === "light" ? "gray.800" : "gray.300",
        },
        _focusVisible: {
          boxShadow: "none",
        },
      },
    }),
  },
  sizes: {
    sm: {
      field: {
        borderRadius: 0,
      },
    },
    md: {
      field: {
        borderRadius: 0,
      },
    },
    lg: {
      field: {
        borderRadius: 0,
      },
    },
  },
};

const theme = extendTheme({
  components: {
    Button,
    Input,
    Select,
    Modal,
  },
});

export default theme;
