import { User } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import supabase from "./supabase";

const useAuth = (): { user: User | null; uid: string | null } => {
  const [user, setUser] = useState<{ user: User | null; uid: string | null }>({
    user: null,
    uid: null,
  });

  useEffect(() => {
    console.log("Auth useEff");
    const userFromAuth = supabase.auth.user();
    setUser({
      user: userFromAuth,
      uid: userFromAuth?.id || null,
    });
    const result = supabase.auth.onAuthStateChange((event) => {
      console.log(`Auth Event ${event}`);
      if (
        event === "SIGNED_IN" ||
        event === "SIGNED_OUT" ||
        event === "USER_UPDATED"
      ) {
        const userFromAuth = supabase.auth.user();
        setUser({
          user: userFromAuth,
          uid: userFromAuth?.id || null,
        });
      }
    });
    return () => {
      if (result.data) {
        result.data.unsubscribe();
      }
    };
  }, []);

  return user;
};

export default useAuth;
