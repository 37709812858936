import { Badge, Tooltip } from "@chakra-ui/react";
import React from "react";

type Props = {
  status: number;
};

const getInfo = (status: number) => {
  switch (status) {
    case -1:
      return {
        colorScheme: "gray",
        text: "Unsaved",
        tooltip: "Deck is offline only, and has not been saved",
      };
    case 0:
      return {
        colorScheme: "teal",
        text: "Private",
        tooltip: "Deck is private, only you can view it",
      };
    case 1:
      return {
        colorScheme: "purple",
        text: "Unlisted",
        tooltip: "Deck is public but wont be listed on the public decks screen",
      };
    case 2:
      return { colorScheme: "blue", text: "Public", tooltip: "Deck is public" };
    default:
      return {
        colorScheme: "gray",
        text: "Unsaved",
        tooltip: "Deck is offline only, and has not been saved",
      };
  }
};

const DeckStatusBadge = ({ status }: Props) => {
  const { colorScheme, text, tooltip } = getInfo(status);
  return (
    <Tooltip label={tooltip}>
      <Badge colorScheme={colorScheme}>{text}</Badge>
    </Tooltip>
  );
};

export default DeckStatusBadge;
