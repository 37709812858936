import { ExternalLinkIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCloseLine,
  RiMenuLine,
} from "react-icons/ri";
import { Link as RouterLink, Route, Routes } from "react-router-dom";
import { useQueryParam } from "use-query-params";
import "./App.css";
import AuthModal from "./components/AuthModal";
import CardQuickViewModal from "./components/CardQuickViewModal";
import UserMenu from "./components/UserMenu";
import {
  DECK_DRAWER_CLOSED_WIDTH,
  DECK_DRAWER_OPEN_WIDTH,
  DISCORD_INV,
  TOP_ALERT_HEIGHT,
  TOP_BAR_HEIGHT,
} from "./config";
import useAuth from "./db/useAuth";
import AboutScreen from "./screens/AboutScreen";
import CardScreen from "./screens/CardScreen";
import DeckBuilderPane from "./screens/DeckBuilderPane";
import DecksScreen from "./screens/DecksScreen";
import LibraryScreen from "./screens/LibraryScreen";
import UserDecksScreen from "./screens/UserDecksScreen";
import useIsMobile from "./utils/useIsMobile";

const shouldShowDiscordAlert = () => {
  try {
    const state = window.localStorage.getItem("discord-alert-dismissed");
    return state !== "true";
  } catch (e) {
    console.error(e);
    return false;
  }
};

const shouldShowv2Alert = () => {
  try {
    const state = window.localStorage.getItem("v2-alert-dismissed");
    return state !== "true";
  } catch (e) {
    console.error(e);
    return false;
  }
};

function App() {
  const [topAlertVisible, setTopAlertVisible] = useState(shouldShowv2Alert);
  const colorMode = useColorMode();
  const ColorModeIcon = useColorModeValue(MoonIcon, SunIcon);
  const borderColor = useColorModeValue("gray.800", "gray.300");
  const deckDrawerState = useDisclosure({ defaultIsOpen: false });
  const authModalState = useDisclosure({ defaultIsOpen: false });

  const isMobile = useIsMobile();

  const handleClickDismissAlert = () => {
    window.localStorage.setItem("v2-alert-dismissed", "true");
    setTopAlertVisible(false);
  };

  const { user: authedUser } = useAuth();

  const screenSize = useBreakpointValue({ base: 0, md: 1, lg: 2 }) || 0;
  const drawerButtonHoverColor = useColorModeValue("blue.500", "blue.400");

  const [viewCardId, setViewCardId] = useQueryParam<string | undefined>("view");

  const ToggleIcon = deckDrawerState.isOpen
    ? RiArrowRightSLine
    : RiArrowLeftSLine;

  const NavMenuItems = [
    {
      label: "Cards",
      to: "/",
    },
    {
      label: "Your Decks",
      to: "/saved-decks",
    },
    {
      label: "Public Decks",
      to: "/deck",
    },
    {
      label: "About",
      to: "/about",
    },
  ];

  const fullTopHeight =
    TOP_BAR_HEIGHT + (topAlertVisible ? TOP_ALERT_HEIGHT : 0);

  return (
    <Box className="App">
      <Collapse in={topAlertVisible} animateOpacity>
        <Box
          w="full"
          zIndex={1}
          position="fixed"
          borderBottomWidth="2px"
          borderBottomColor={borderColor}
          h={TOP_ALERT_HEIGHT}
          mt={TOP_BAR_HEIGHT}
        >
          <Alert h={TOP_ALERT_HEIGHT}>
            <AlertIcon />
            <AlertTitle>
              <HStack>
                <Text>Version 2 of OPCards is coming soon.</Text>
                <Button
                  ml={2}
                  variant="ghost"
                  leftIcon={<Icon as={RiCloseLine} />}
                  size="xs"
                  onClick={handleClickDismissAlert}
                >
                  Dismiss
                </Button>
              </HStack>
            </AlertTitle>
          </Alert>
        </Box>
      </Collapse>

      <VStack>
        <HStack
          position="fixed"
          w="full"
          bgColor="chakra-body-bg"
          h={TOP_BAR_HEIGHT}
          zIndex={1}
          borderBottomColor={borderColor}
          borderBottomWidth="4px"
          borderBottomStyle="solid"
        >
          <HStack px={isMobile ? 2 : 8} width="full" justify="space-between">
            <HStack spacing={isMobile ? 4 : 8}>
              {isMobile && (
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="ghost"
                    leftIcon={<Icon as={RiMenuLine} />}
                  >
                    OPCards
                  </MenuButton>
                  <MenuList>
                    {NavMenuItems.map(({ label, to }) => (
                      <MenuItem as={RouterLink} to={to}>
                        {label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              )}
              {!isMobile && (
                <>
                  <Heading size="lg">
                    {isMobile ? "OPCards" : "One Piece TCG"}
                  </Heading>
                  <HStack spacing={4}>
                    {NavMenuItems.map(({ label, to }) => (
                      <Button variant="ghost" as={RouterLink} to={to}>
                        {label}
                      </Button>
                    ))}
                  </HStack>
                </>
              )}
            </HStack>

            <HStack>
              {authedUser ? (
                <UserMenu />
              ) : (
                <Button onClick={authModalState.onOpen}>Sign In</Button>
              )}
              <IconButton
                aria-label="Toggle Light/Dark Theme"
                icon={<ColorModeIcon />}
                onClick={colorMode.toggleColorMode}
              />
            </HStack>
          </HStack>
        </HStack>

        <Box w="full">
          <Box
            position="fixed"
            right={
              isMobile
                ? 2
                : deckDrawerState.isOpen
                ? DECK_DRAWER_OPEN_WIDTH
                : DECK_DRAWER_CLOSED_WIDTH
            }
            top={fullTopHeight}
          >
            {isMobile && <Text>View Deck</Text>}
            <IconButton
              onClick={deckDrawerState.onToggle}
              _hover={{
                color: drawerButtonHoverColor,
                "> :first-child": {
                  transform: deckDrawerState.isOpen
                    ? "translateX(4px)"
                    : "translateX(-4px)",
                },
              }}
              _active={{}}
              aria-label="Expand Deck Drawer"
              variant="ghost"
              size="lg"
              icon={
                <Icon
                  as={ToggleIcon}
                  transition="transform 200ms"
                  boxSize={16}
                />
              }
            />
          </Box>

          <Box
            pt={fullTopHeight}
            px={isMobile ? "1" : "8"}
            pr={
              isMobile
                ? 0
                : deckDrawerState.isOpen
                ? DECK_DRAWER_OPEN_WIDTH
                : DECK_DRAWER_CLOSED_WIDTH
            }
          >
            <Routes>
              <Route
                path="/"
                element={
                  <LibraryScreen
                    onClickCard={(id: string) => setViewCardId(id)}
                  />
                }
              />
              <Route path="/card/:cardID" element={<CardScreen />} />
              <Route path="/deck" element={<DecksScreen />} />
              <Route path="/saved-decks" element={<UserDecksScreen />} />
              <Route path="/about" element={<AboutScreen />} />
            </Routes>
          </Box>
        </Box>
        <Modal
          isCentered
          scrollBehavior="inside"
          size="3xl"
          isOpen={!!viewCardId}
          onClose={() => setViewCardId(undefined)}
        >
          <ModalOverlay />
          <ModalContent>
            {!!viewCardId && <CardQuickViewModal cardId={viewCardId} />}
          </ModalContent>
        </Modal>
        <Modal
          size="lg"
          isOpen={authModalState.isOpen}
          onClose={authModalState.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <AuthModal />
          </ModalContent>
        </Modal>
      </VStack>

      {screenSize >= 1 ? (
        <DeckBuilderPane
          top={fullTopHeight}
          onClickCard={(id: string) => setViewCardId(id)}
          drawerState={deckDrawerState}
          isInDrawer={false}
        />
      ) : (
        <Drawer
          size="lg"
          isOpen={deckDrawerState.isOpen}
          onClose={deckDrawerState.onClose}
          placement="right"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Deck Builder</DrawerHeader>
            <DrawerBody p="0">
              <DeckBuilderPane
                onClickCard={(id: string) => setViewCardId(id)}
                drawerState={deckDrawerState}
                isInDrawer
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
}

export default App;
