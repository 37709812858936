import {
  Image,
  Badge,
  Center,
  Divider,
  Heading,
  HStack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { format, formatDistanceToNow } from "date-fns";
import { OnlineDeck } from "../deck";
import cardback from "../cardback.jpg";
import DeckStatusBadge from "./DeckStatusBadge";

type PublicDeckProps = {
  type: "public";
  deck: OnlineDeck & {
    profile: {
      id: string;
      username: string;
    };
  };
};

type OwnDeckProps = {
  type: "own";
  deck: OnlineDeck;
};

type Props = (PublicDeckProps | OwnDeckProps) & {
  onClick: () => void;
};

const DeckCard = ({ deck, onClick, type }: Props) => {
  return (
    <VStack
      onClick={onClick}
      align="start"
      role="button"
      borderWidth="1px"
      borderStyle="solid"
      p={4}
      m={2}
      _hover={{ borderColor: "blue.500", cursor: "pointer" }}
    >
      <HStack>
        <Badge>id {deck.id.slice(-10)}</Badge>
        <Tooltip label={format(deck.mTime, "PPPppp")}>
          <Text fontSize="xs" fontStyle="italic">
            {`Last Updated ${formatDistanceToNow(deck.mTime)} ago`}
          </Text>
        </Tooltip>
      </HStack>
      <Heading size="sm">{deck.name}</Heading>
      <HStack>
        {" "}
        <DeckStatusBadge status={deck.status} />
        {type === "public" && (
          <Text fontSize="sm">Created by {deck.profile.username}</Text>
        )}
      </HStack>
      <Divider />
      <Text>Leader: {deck.leader?.name}</Text>
      <Center w="full">
        {deck.leader && (
          <Image
            fallbackSrc={cardback}
            boxSize={"48"}
            objectFit="contain"
            src={`/cardthumb${deck.leader?.printings[0]?.artUrl}`}
          />
        )}
      </Center>
    </VStack>
  );
};

export default DeckCard;
