import { Card, LeaderCard } from "./card";

import p from "./data/P.json";
import st01 from "./data/ST01.json";
import st02 from "./data/ST02.json";
import st03 from "./data/ST03.json";
import st04 from "./data/ST04.json";
import st05 from "./data/ST05.json";
import st06 from "./data/ST06.json";
import st07 from "./data/ST07.json";
import op01 from "./data/OP01.json";
import op02 from "./data/OP02.json";
import op03 from "./data/OP03.json";
import op04 from "./data/OP04.json";

const CardsList: Card[] = [
  ...st01,
  ...st02,
  ...st03,
  ...st04,
  ...st05,
  ...st06,
  ...st07,
  ...op01,
  ...op02,
  ...op03,
  ...op04,
  ...p,
] as Card[];

export const CardsByID: Record<string, Card> = {};
export const LeadersByID: Record<string, LeaderCard> = {};

CardsList.forEach((card) => {
  CardsByID[card.id] = card;
  if (card.type === "leader") {
    LeadersByID[card.id] = card;
  }
});

export default CardsList;

export const Traits: string[] = [
  ...new Set(CardsList.flatMap((card) => card.traits)),
].sort((a, b) => a.localeCompare(b));

export const Sets: string[] = [
  ...new Set(CardsList.flatMap((card) => card.set)),
];

export const Rarities: string[] = [
  ...new Set(CardsList.flatMap((card) => card.rarity)),
];
