import {
  Box,
  Button,
  chakra,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import supabase from "../db/supabase";
import googleLogo from "./g-logo.png";

type SignInState = "ready" | "loading" | "link-sent";

const SignIn = () => {
  const [state, setState] = useState<SignInState>("ready");
  const [googleLoading, setGoogleLoading] = useState<boolean>(false);
  const [isMagicLink, setIsMagicLink] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  async function handleClickGoogle() {
    setGoogleLoading(true);
    await supabase.auth.signIn(
      {
        provider: "google",
      },
      { redirectTo: window.location.origin }
    );
  }
  const handleClickSignIn = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      setState("loading");
      const { error } = await supabase.auth.signIn(
        { email, password },
        { redirectTo: window.location.origin }
      );
      if (error) throw error;
    } catch (error) {
      setError((error as any).error_description || "Something went wrong");
    } finally {
      setState("link-sent");
    }
  };
  return state === "link-sent" ? (
    <Text>A link has been sent to your email address. Click it to log in.</Text>
  ) : (
    <VStack pb={4}>
      <Button
        isLoading={googleLoading}
        onClick={handleClickGoogle}
        pl={0}
        _hover={{ bg: "white", color: "gray.800" }}
        _active={{ bg: "white", color: "blue.500" }}
      >
        <Box h="full" mr={2} px={3} py={2} bg="white">
          <Image
            w="full"
            h="full"
            align="center"
            fit="contain"
            src={googleLogo}
          />
        </Box>
        Sign in with Google
      </Button>
      {/* 
      <Divider /><Heading size="sm">Or Sign In with an email and password</Heading>
      <chakra.form w="full">
        <VStack w="full" spacing={6} align="end">
          <VStack w="full">
            <FormControl isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
              {error !== null && isMagicLink && (
                <FormErrorMessage>{error}</FormErrorMessage>
              )}
            </FormControl>
            {!isMagicLink && (
              <FormControl isRequired isInvalid={error !== null}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                />
                {error !== null && !isMagicLink && (
                  <FormErrorMessage>{error}</FormErrorMessage>
                )}
              </FormControl>
            )}
            <FormControl>
              <FormLabel htmlFor="magic-link">Use Magic Link</FormLabel>
              <Switch
                isChecked={isMagicLink}
                onChange={(e) => setIsMagicLink(e.target.checked)}
                id="magic-link"
              />
            </FormControl>
          </VStack>
          <Button
            isLoading={state === "loading"}
            colorScheme="purple"
            onClick={handleClickSignIn}
          >
            Sign In
          </Button>
        </VStack>
      </chakra.form> */}
    </VStack>
  );
};

export default SignIn;
