import {
  Badge,
  Container,
  effect,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
  Image,
  IconButton,
  Icon,
  Button,
  Link,
  Box,
  Flex,
  useBreakpointValue,
  TableContainer,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import { useParams, Link as RouterLink } from "react-router-dom";
import CardsList from "../cards";
import cardback from "../cardback.jpg";
import { useDeck } from "../deck";
import {
  RiAddLine,
  RiDeleteBin7Line,
  RiSubtractLine,
  RiBrushLine,
} from "react-icons/ri";
import React, { useState } from "react";

const traitRegex = /\{(.+?)\}/;
const recursivelyBuildEffectText = (
  text: string,
  result: React.ReactNode[] = []
): React.ReactNode[] => {
  if (text.length <= 0) return result;
  const regexRes = traitRegex.exec(text);
  if (regexRes === null) {
    return [...result, text];
  }
  return recursivelyBuildEffectText(
    text.slice(regexRes.index + (regexRes[0]?.length ?? 0)),
    [
      text.slice(0, regexRes.index),
      ...result,
      <Link as={RouterLink} to={`/?trait=${regexRes[1]}`}>
        {regexRes[0]}
      </Link>,
    ]
  );
};

const CardScreen = () => {
  const cardID = useParams().cardID;
  const card = CardsList.find((card) => card.id === cardID);
  console.log(card);
  const screenSize =
    useBreakpointValue({ base: 0, md: 1, lg: 2, xl: 3, "2xl": 4 }) || 0;

  const { deck, quantityByID, addCard, removeCard } = useDeck();

  const [printingIdx, setPrintingIdx] = useState(0);

  const handleChangePrinting = (variant: string) => {
    const idx = card?.printings.findIndex((p) => p.variant === variant);
    console.log({ variant, idx });
    if (idx !== undefined) {
      setPrintingIdx(idx);
    }
  };

  console.log(card?.printings);

  const emptyColor = useColorModeValue("blackAlpha.600", "whiteAlpha.600");
  if (cardID === undefined || !card) {
    return (
      <Center w="full" minH="50vh">
        <Heading>404</Heading>
        <Text>Not found</Text>
      </Center>
    );
  }

  const printing = card.printings[printingIdx] || card.printings[0];
  const hasMultiplePrintings = card.printings.length >= 2;

  let powerStrings: [string, string] = ["", ""];
  if (card.type === "character" || card.type === "leader") {
    const pwr = card.power.toString();
    const firstIdx = pwr.indexOf("0");
    powerStrings = [pwr.slice(0, firstIdx), pwr.slice(firstIdx)];
  }

  const interactiveText = card.effect.map((eff) =>
    recursivelyBuildEffectText(eff)
  );
  // Build text so we can click things

  return (
    <Container maxW="container.xl">
      <VStack spacing={8} w="full">
        <VStack w="full" align="start">
          <Heading>{card.name}</Heading>
          <Badge
            colorScheme={card.colors.length === 1 ? card.colors[0] : "gray"}
          >
            {card.id}
          </Badge>
        </VStack>
        <Flex w="full" align="start" py="2" wrap="wrap">
          <Box pos="relative">
            <Image
              fallbackSrc={cardback}
              boxSize={"md"}
              objectFit="contain"
              src={`/cardimg${printing?.artUrl}`}
            />
            {printing?.artist && (
              <Tooltip label="Artist">
                <HStack
                  w="full"
                  spacing="1"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon boxSize="3" as={RiBrushLine} alignSelf="center" />
                  <Text fontSize="sm">{printing.artist}</Text>
                </HStack>
              </Tooltip>
            )}
          </Box>
          <VStack align="start" spacing={4}>
            <TableContainer whiteSpace="unset">
              <Table
                size={screenSize >= 1 ? "md" : "sm"}
                variant="simple"
                borderWidth="2px"
                maxW={screenSize >= 4 ? "2xl" : screenSize >= 3 ? "lg" : "sm"}
              >
                <Tbody>
                  <Tr>
                    <Td borderLeftWidth="2px">Effect:</Td>
                    <Td colSpan={3}>
                      {interactiveText.map((eff) => (
                        <Box>{eff}</Box>
                      ))}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderLeftWidth="2px">Color:</Td>
                    <Td textTransform="capitalize">
                      {card.colors.map((col, idx) => (
                        <span key={col}>
                          {idx >= 1 && " / "}
                          <Link
                            color={`${col}.600`}
                            as={RouterLink}
                            to={`/?col=${col}`}
                          >
                            {col}
                          </Link>
                        </span>
                      ))}
                    </Td>
                    <Td borderLeftWidth="2px">Card Type:</Td>
                    <Td textTransform="capitalize">
                      <Link as={RouterLink} to={`/?type=${card.type}`}>
                        {card.type}
                      </Link>
                    </Td>
                  </Tr>
                  {card.type === "character" && (
                    <>
                      <Tr>
                        <Td borderLeftWidth="2px">Cost:</Td>
                        <Td>{card.cost}</Td>
                        <Td borderLeftWidth="2px">Attack Type:</Td>
                        <Td textTransform="capitalize">{card.attribute}</Td>
                      </Tr>
                    </>
                  )}
                  {(card.type === "character" || card.type === "leader") && (
                    <Tr>
                      <Td borderLeftWidth="2px">Power:</Td>
                      <Td>
                        <Text as="span" fontSize="xl" fontWeight="bold">
                          {powerStrings[0]}
                        </Text>
                        <Text as="span" fontSize="sm">
                          {powerStrings[1]}
                        </Text>
                      </Td>
                      {card.type === "character" && (
                        <>
                          <Td borderLeftWidth="2px">Counter:</Td>
                          <Td>
                            {card.counter ?? (
                              <Text textColor={emptyColor} fontStyle="italic">
                                None
                              </Text>
                            )}
                          </Td>
                        </>
                      )}
                      {card.type === "leader" && (
                        <>
                          <Td borderLeftWidth="2px">Life:</Td>
                          <Td>
                            {card.life ?? (
                              <Text textColor={emptyColor} fontStyle="italic">
                                None
                              </Text>
                            )}
                          </Td>
                        </>
                      )}
                    </Tr>
                  )}
                  <Tr>
                    <Td borderLeftWidth="2px">Traits:</Td>
                    <Td>
                      {card.traits.map((trait, idx) => (
                        <>
                          {idx >= 1 && " / "}
                          <Link as={RouterLink} to={`/?trait=${trait}`}>
                            {trait}
                          </Link>
                        </>
                      ))}
                    </Td>
                    <Td borderLeftWidth="2px">Rarity:</Td>
                    <Td textTransform="capitalize">{card.rarity}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            {card.type === "leader" ? (
              quantityByID(card.id) >= 1 ? (
                <Button
                  onClick={() => removeCard(card)}
                  variant="ghost"
                  leftIcon={<Icon as={RiDeleteBin7Line} />}
                >
                  Remove As leader
                </Button>
              ) : (
                <Button
                  onClick={() => addCard(card)}
                  variant="ghost"
                  leftIcon={<Icon as={RiAddLine} />}
                >
                  Add as Leader
                </Button>
              )
            ) : quantityByID(card.id) >= 1 ? (
              <VStack spacing={0} align="start">
                <Heading size="sm">Number in deck</Heading>
                <HStack>
                  <IconButton
                    onClick={() => removeCard(card)}
                    variant="ghost"
                    aria-label="Remove from deck"
                    icon={<Icon as={RiSubtractLine} />}
                  />
                  <Text>{quantityByID(card.id)}</Text>
                  <IconButton
                    onClick={() => addCard(card)}
                    variant="ghost"
                    aria-label="Add to deck"
                    icon={<Icon as={RiAddLine} />}
                  />
                </HStack>
              </VStack>
            ) : (
              <VStack spacing={0} align="start">
                <Button
                  onClick={() => addCard(card)}
                  variant="ghost"
                  leftIcon={<Icon as={RiAddLine} />}
                >
                  Add To Deck
                </Button>
              </VStack>
            )}
          </VStack>
        </Flex>
        {hasMultiplePrintings && (
          <VStack w="full" align="start">
            <Heading size="sm">Printings</Heading>
            <Flex w="full" align="start" py="2" wrap="wrap">
              {card.printings.map((print) => (
                <Box
                  key={print.variant}
                  w="160px"
                  px="4"
                  role="button"
                  onClick={() => handleChangePrinting(print.variant)}
                >
                  <Heading size="xs">{print.label}</Heading>
                  <Image
                    fallbackSrc={cardback}
                    boxSize="24"
                    objectFit="contain"
                    src={`/cardimg${print?.artUrl}`}
                  />
                </Box>
              ))}
            </Flex>
          </VStack>
        )}
      </VStack>
    </Container>
  );
};
export default CardScreen;
