import {
  Badge,
  Box,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  RiArrowRightFill,
  RiCameraSwitchFill,
  RiBrushLine,
} from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import cardback from "../cardback.jpg";
import { CardsByID } from "../cards";

type Props = {
  cardId: string;
};

const CardQuickViewModal = ({ cardId }: Props) => {
  const card = CardsByID[cardId];
  const dataLabelColor = useColorModeValue("purple.700", "purple.300");
  const emptyColor = useColorModeValue("blackAlpha.600", "whiteAlpha.600");
  const screenSize = useBreakpointValue({ base: 0, md: 1 }) || 0;

  const [printingIdx, setPrintingIdx] = useState(0);

  let powerStrings: [string, string] = ["", ""];
  if (card?.type === "character" || card?.type === "leader") {
    const pwr = card.power.toString();
    if (pwr.length === 1) {
      powerStrings = ["0", ""];
    } else {
      const firstIdx = pwr.indexOf("0");
      powerStrings = [pwr.slice(0, firstIdx), pwr.slice(firstIdx)];
    }
  }

  if (cardId === undefined || !card) {
    return (
      <>
        <ModalHeader>Card Not Found</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center w="full" minH="30vh">
            <Text>No Data...</Text>
          </Center>
        </ModalBody>
      </>
    );
  }

  const printing = card.printings[0];
  const hasMultiplePrintings = card.printings.length >= 2;

  return (
    <>
      <ModalHeader>
        {card?.name}
        <HStack w="full" justify="space-between">
          <Badge
            colorScheme={card?.colors.length === 1 ? card?.colors[0] : "gray"}
          >
            {card?.id}
          </Badge>
          <Button
            variant="ghost"
            size="sm"
            rightIcon={<Icon as={RiArrowRightFill} />}
            as={RouterLink}
            to={`card/${card.id}`}
          >
            More Info
          </Button>
        </HStack>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack
          direction={screenSize === 0 ? "column" : "row"}
          w="full"
          align="start"
          spacing="4"
        >
          <VStack spacing={1}>
            <Box pos="relative">
              <Image
                align="top"
                fallbackSrc={cardback}
                boxSize={"md"}
                objectFit="contain"
                src={`/cardimg${printing?.artUrl}`}
              />
            </Box>
          </VStack>
          <VStack justify="start" w="full">
            <HStack px="1" bg="blackAlpha.100" w="full" justify="space-between">
              <Text color={dataLabelColor} fontWeight="600">
                Name
              </Text>
              <Text>{card.name}</Text>
            </HStack>
            <HStack px="1" bg="blackAlpha.100" w="full" justify="space-between">
              <Text color={dataLabelColor} fontWeight="600">
                Color
              </Text>
              <Text>
                {card.colors.map((col, idx) => (
                  <>
                    {idx >= 1 && " / "}
                    <Link
                      textTransform="capitalize"
                      color={`${col}.600`}
                      as={RouterLink}
                      to={`/?col=${col}`}
                    >
                      {col}
                    </Link>
                  </>
                ))}
              </Text>
            </HStack>
            <HStack px="1" bg="blackAlpha.100" w="full" justify="space-between">
              <Text color={dataLabelColor} fontWeight="600">
                Card Type
              </Text>
              <Link
                textTransform="capitalize"
                as={RouterLink}
                to={`/?type=${card.type}`}
              >
                {card.type}
              </Link>
            </HStack>
            <HStack px="1" bg="blackAlpha.100" w="full" justify="space-between">
              <Text color={dataLabelColor} fontWeight="600">
                Effect
              </Text>
              <Text>{card.effect.join("\n")}</Text>
            </HStack>
            {card.type !== "leader" && (
              <HStack
                px="1"
                bg="blackAlpha.100"
                w="full"
                justify="space-between"
              >
                <Text color={dataLabelColor} fontWeight="600">
                  Cost
                </Text>
                <Text>{card.cost}</Text>
              </HStack>
            )}
            {(card.type === "character" || card.type === "leader") && (
              <HStack
                px="1"
                bg="blackAlpha.100"
                w="full"
                justify="space-between"
              >
                <Text color={dataLabelColor} fontWeight="600">
                  Power
                </Text>
                <Text>
                  <Text as="span" fontSize="xl" fontWeight="bold">
                    {powerStrings[0]}
                  </Text>
                  <Text as="span" fontSize="sm">
                    {powerStrings[1]}
                  </Text>
                </Text>
              </HStack>
            )}
            {card.type === "character" && (
              <>
                <HStack
                  px="1"
                  bg="blackAlpha.100"
                  w="full"
                  justify="space-between"
                >
                  <Text color={dataLabelColor} fontWeight="600">
                    Attribute
                  </Text>
                  <Text textTransform="capitalize">{card.attribute}</Text>
                </HStack>
                <HStack
                  px="1"
                  bg="blackAlpha.100"
                  w="full"
                  justify="space-between"
                >
                  <Text color={dataLabelColor} fontWeight="600">
                    Counter
                  </Text>
                  <Text>
                    {card.counter || (
                      <Text textColor={emptyColor} fontStyle="italic">
                        None
                      </Text>
                    )}
                  </Text>
                </HStack>
              </>
            )}
            {card.type === "leader" && (
              <HStack
                px="1"
                bg="blackAlpha.100"
                w="full"
                justify="space-between"
              >
                <Text color={dataLabelColor} fontWeight="600">
                  Life
                </Text>
                <Text>{card.life}</Text>
              </HStack>
            )}
            <HStack px="1" bg="blackAlpha.100" w="full" justify="space-between">
              <Text color={dataLabelColor} fontWeight="600">
                Traits
              </Text>
              <Text>
                {card.traits.map((trait, idx) => (
                  <>
                    {idx >= 1 && " / "}
                    <Link as={RouterLink} to={`/?trait=${trait}`}>
                      {trait}
                    </Link>
                  </>
                ))}
              </Text>
            </HStack>
            <HStack px="1" bg="blackAlpha.100" w="full" justify="space-between">
              <Text color={dataLabelColor} fontWeight="600">
                Rarity
              </Text>
              <Text>{card.rarity}</Text>
            </HStack>

            {printing?.artist && (
              <HStack w="full" alignItems="center" justifyContent="start">
                <Tooltip label="Artist">
                  <HStack alignItems="center" spacing="1">
                    <Icon
                      boxSize="3"
                      as={RiBrushLine}
                      alignSelf="center"
                      justifyContent="center"
                    />
                    <Text fontSize="sm">{printing.artist}</Text>
                  </HStack>
                </Tooltip>
              </HStack>
            )}
            {hasMultiplePrintings && (
              <Text alignSelf="start" fontSize="xs">
                Card has alternate printings,{" "}
                <Link as={RouterLink} to={`card/${card.id}`}>
                  click here to view
                </Link>
              </Text>
            )}
          </VStack>
        </Stack>
      </ModalBody>
    </>
  );
};

export default CardQuickViewModal;
