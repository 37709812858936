import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { clamp } from "lodash";
import { useEffect, useState } from "react";
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { SetQuery, StringParam, useQueryParams } from "use-query-params";
import { Card } from "../card";
import CardsList, { Rarities, Sets, Traits } from "../cards";
import CardItem from "../components/CardItem";
import { useDeck } from "../deck";

const Colors = ["red", "blue", "green", "purple", "black", "yellow"] as const;
const CardTypes = ["character", "event", "stage", "leader"] as const;
type ColorOptions = typeof Colors[number];

const SortOptions = ["id", "name", "power", "cost"] as const;
type SortOptions = typeof SortOptions[number];

type Filters = {
  q: string | null | undefined;
  col: string | null | undefined;
  type: string | null | undefined;
  trait: string | null | undefined;
  set: string | null | undefined;
  rarity: string | null | undefined;
  sort: string | null | undefined;
};

const getCardsPower = (card: Card) =>
  card.type === "character" || card.type === "leader" ? card.power : 0;
const getCardsCost = (card: Card) => (card.type === "leader" ? 0 : card.cost);

const getSortFunction = (sortBy: string | null | undefined) => {
  switch (sortBy) {
    case "name":
      return (a: Card, b: Card) => a.name.localeCompare(b.name);
    case "power":
      return (a: Card, b: Card) => getCardsPower(a) - getCardsPower(b);
    case "cost":
      return (a: Card, b: Card) => getCardsCost(a) - getCardsCost(b);
    case "id":
    default:
      return (a: Card, b: Card) => a.id.localeCompare(b.id);
  }
};

type FilterResult =
  | {
      status: "loading";
    }
  | {
      status: "done";
      cards: Card[];
    };

const PAGE_LENGTH = 40;

const useFilteredCards = (query: Filters): FilterResult => {
  const [result, setResult] = useState<FilterResult>({ status: "loading" });
  useEffect(() => {
    console.log("Filtering");
    setResult({ status: "loading" });
    setTimeout(() => {
      const cards = CardsList.filter((card) => {
        if (
          query.q &&
          !card.name.toLowerCase().includes(query.q.toLowerCase())
        ) {
          return false;
        }
        if (query.col && !card.colors.includes(query.col as any)) {
          return false;
        }
        if (query.type && card.type !== query.type) {
          return false;
        }
        if (query.trait && !card.traits.includes(query.trait)) {
          return false;
        }
        if (query.set && card.set !== query.set) {
          return false;
        }
        if (query.rarity && card.rarity !== query.rarity) {
          return false;
        }
        return true;
      });
      // Sort
      const sorted = cards.sort(getSortFunction(query.sort));
      setResult({ cards: sorted, status: "done" });
    }, 50);
  }, [query]);
  return result;
};

const QueryConfig = {
  q: StringParam,
  col: StringParam,
  type: StringParam,
  trait: StringParam,
  set: StringParam,
  rarity: StringParam,
  sort: StringParam,
};

type Props = {
  onClickCard: (id: string) => void;
};

const LibraryScreen = ({ onClickCard }: Props) => {
  const { deck, quantityByID, addCard, removeCard } = useDeck();
  const [page, setPage] = useState(0);
  const [query, internalSetQuery] = useQueryParams(QueryConfig);

  const setQuery = (...args: Parameters<SetQuery<typeof QueryConfig>>) => {
    internalSetQuery(...args);
    setPage(0);
  };

  // Filter the cards
  const filteredCards = useFilteredCards(query);

  // Paginate

  const startCardIdx = PAGE_LENGTH * page;
  const cardsToShow =
    filteredCards.status === "done"
      ? filteredCards.cards.slice(startCardIdx, startCardIdx + PAGE_LENGTH)
      : [];
  const totalNumCards =
    filteredCards.status === "done" ? filteredCards.cards.length : 0;
  const endCardIdx = clamp(startCardIdx + PAGE_LENGTH, totalNumCards);
  const totalNumPages = Math.ceil(totalNumCards / PAGE_LENGTH);

  const nextPageEnabled = page < totalNumPages - 1;
  const prevPageEnabled = page >= 1;

  return (
    <VStack spacing={8}>
      <HStack w="full">
        <Heading>Cards</Heading>
      </HStack>

      <VStack w="full" align="start">
        <Heading as="h3" size="sm">
          Filters
        </Heading>
        <Button
          onClick={() => setQuery({}, "replace")}
          leftIcon={<Icon as={RiCloseCircleLine} />}
          variant="ghost"
          size="sm"
        >
          Clear All
        </Button>
        <Flex wrap="wrap" w="full">
          <FormControl px={1} maxW="180px">
            <FormLabel>Search Name</FormLabel>
            <Input
              onChange={(e) => setQuery({ q: e.target.value })}
              value={query.q || ""}
            />
          </FormControl>

          <FormControl px={1} maxW="180px">
            <FormLabel>Color</FormLabel>
            <Select
              textTransform="capitalize"
              onChange={(e) => setQuery({ col: e.target.value })}
              value={query.col || ""}
            >
              <option value="">All</option>
              {Colors.map((opt) => (
                <Box
                  key={opt}
                  as="option"
                  value={opt}
                  textTransform="capitalize"
                >
                  {opt}
                </Box>
              ))}
            </Select>
          </FormControl>
          <FormControl px={1} maxW="180px">
            <FormLabel>Type</FormLabel>
            <Select
              textTransform="capitalize"
              onChange={(e) => setQuery({ type: e.target.value })}
              value={query.type || ""}
            >
              <option value="">All</option>
              {CardTypes.map((opt) => (
                <Box
                  key={opt}
                  as="option"
                  value={opt}
                  textTransform="capitalize"
                >
                  {opt}
                </Box>
              ))}
            </Select>
          </FormControl>

          <FormControl px={1} maxW="180px">
            <FormLabel>Trait</FormLabel>
            <Select
              textTransform="capitalize"
              onChange={(e) => setQuery({ trait: e.target.value })}
              value={query.trait || ""}
            >
              <option value="">All</option>
              {Traits.map((opt) => (
                <Box
                  key={opt}
                  as="option"
                  value={opt}
                  textTransform="capitalize"
                >
                  {opt}
                </Box>
              ))}
            </Select>
          </FormControl>

          <FormControl px={1} maxW="180px">
            <FormLabel>Set</FormLabel>
            <Select
              textTransform="capitalize"
              onChange={(e) => setQuery({ set: e.target.value })}
              value={query.set || ""}
            >
              <option value="">All</option>
              {Sets.map((opt) => (
                <Box
                  key={opt}
                  as="option"
                  value={opt}
                  textTransform="capitalize"
                >
                  {opt}
                </Box>
              ))}
            </Select>
          </FormControl>
          <FormControl px={1} maxW="180px">
            <FormLabel>Rarity</FormLabel>
            <Select
              textTransform="capitalize"
              onChange={(e) => setQuery({ rarity: e.target.value })}
              value={query.rarity || ""}
            >
              <option value="">All</option>
              {Rarities.map((opt) => (
                <Box
                  key={opt}
                  as="option"
                  value={opt}
                  textTransform="capitalize"
                >
                  {opt}
                </Box>
              ))}
            </Select>
          </FormControl>

          <FormControl px={1} maxW="180px">
            <FormLabel>Sort By</FormLabel>
            <Select
              colorScheme="blue"
              textTransform="capitalize"
              onChange={(e) => setQuery({ sort: e.target.value })}
              value={query.sort || "Id"}
            >
              {SortOptions.map((opt) => (
                <Box
                  key={opt}
                  as="option"
                  value={opt}
                  textTransform="capitalize"
                >
                  {opt}
                </Box>
              ))}
            </Select>
          </FormControl>
        </Flex>

        {filteredCards.status === "loading" ? (
          <Center w="full" minH="200px">
            <Spinner size="xl" ringColor="blue.500" emptyColor="gray.50" />
          </Center>
        ) : (
          <>
            <VStack spacing={0} align="start">
              <Text>
                Showing cards {startCardIdx + 1} to {endCardIdx}{" "}
                <Text fontStyle="italic" as="span" fontSize="sm">
                  (of {totalNumCards})
                </Text>
              </Text>
              <HStack>
                <IconButton
                  colorScheme="blue"
                  onClick={() => setPage(page - 1)}
                  disabled={!prevPageEnabled}
                  variant="ghost"
                  aria-label="Previous Page"
                  icon={<Icon as={RiArrowLeftLine} />}
                />
                <IconButton
                  colorScheme="blue"
                  onClick={() => setPage(page + 1)}
                  disabled={!nextPageEnabled}
                  variant="ghost"
                  aria-label="Next Page"
                  icon={<Icon as={RiArrowRightLine} />}
                />
              </HStack>
            </VStack>

            <Flex py={4} wrap="wrap">
              {cardsToShow.map((card) => (
                <CardItem
                  card={card}
                  key={card.id}
                  onClick={() => onClickCard(card.id)}
                />
              ))}
            </Flex>
            <VStack pb={4} spacing={0} align="start">
              <Text>
                Showing cards {startCardIdx + 1} to {endCardIdx}{" "}
                <Text fontStyle="italic" as="span" fontSize="sm">
                  (of {totalNumCards})
                </Text>
              </Text>
              <HStack>
                <IconButton
                  colorScheme="blue"
                  onClick={() => setPage(page - 1)}
                  disabled={!prevPageEnabled}
                  variant="ghost"
                  aria-label="Previous Page"
                  icon={<Icon as={RiArrowLeftLine} />}
                />
                <IconButton
                  colorScheme="blue"
                  onClick={() => setPage(page + 1)}
                  disabled={!nextPageEnabled}
                  variant="ghost"
                  aria-label="Next Page"
                  icon={<Icon as={RiArrowRightLine} />}
                />
              </HStack>
            </VStack>
          </>
        )}
      </VStack>
    </VStack>
  );
};

export default LibraryScreen;
