import { Badge, Flex, HStack, Icon, Text, Tooltip } from "@chakra-ui/react";
import { RiAlertFill } from "react-icons/ri";
import { Card } from "./card";

type Props = {
  card: Card;
  quantity: number;
  children?: React.ReactNode;
  onClickCard: (id: string) => void;
};

const DeckBuilderCard = ({ card, quantity, children, onClickCard }: Props) => {
  return (
    <HStack
      key={card.id}
      w="full"
      justify="space-between"
      borderBottomWidth="2px"
      py={1}
    >
      <HStack>
        <HStack role="button" onClick={() => onClickCard(card.id)}>
          <Badge
            colorScheme={card.colors.length === 1 ? card.colors[0] : "gray"}
          >
            {card.id}
          </Badge>
          <Text noOfLines={1}>{card.name}</Text>
        </HStack>

        {quantity >= 1 && (
          <Text fontSize="sm">
            x
            <Text
              fontSize="md"
              as="span"
              fontWeight="bold"
              color={quantity > 4 ? "red.500" : undefined}
            >
              {quantity}
            </Text>
          </Text>
        )}
        {quantity > 4 && (
          <Tooltip label="You can only have 4 of each card in a deck">
            <Flex>
              <Icon as={RiAlertFill} color="red.500" />
            </Flex>
          </Tooltip>
        )}
      </HStack>
      {children}
    </HStack>
  );
};

export default DeckBuilderCard;
