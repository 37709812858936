import {
  Badge,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import DeckCard from "../components/DeckCard";
import supabase from "../db/supabase";
import useAuth from "../db/useAuth";
import { OnlineDeck, savedDeckToDeck, useDeck } from "../deck";

type PublicDeck = OnlineDeck & {
  profile: {
    id: string;
    username: string;
  };
};

type State =
  | {
      status: "done";
      decks: PublicDeck[];
    }
  | {
      status: "loading";
    }
  | {
      status: "error";
      error: string;
    };

const DecksScreen = () => {
  const [state, setState] = useState<State>({ status: "loading" });
  const [modalState, setModalState] = useState<null | PublicDeck>(null);
  const { loadDeck } = useDeck();
  const auth = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const result = await supabase
        .from("deck")
        .select(
          `
            *,
            profile (
                *
            )
        `
        )
        .eq("status", 2)
        .range(0, 19);
      if (result.error || result.data === null) {
        setState({ status: "error", error: "Failed to fetch" });
        return;
      }
      setState({
        status: "done",
        decks: result.data.map((deck) => ({
          ...savedDeckToDeck(deck),
          profile: deck.profile,
        })),
      });
    };
    fetchData();
  }, []);
  return (
    <VStack w="full" align="start" spacing={8}>
      <Heading>Public Decks</Heading>
      {state.status === "loading" && (
        <Flex flexWrap="wrap">
          <Skeleton m={2} w="64" h="64" />
          <Skeleton m={2} w="64" h="64" />
          <Skeleton m={2} w="64" h="64" />
          <Skeleton m={2} w="64" h="64" />
          <Skeleton m={2} w="64" h="64" />
        </Flex>
      )}
      <Flex wrap="wrap">
        {state.status === "done" &&
          state.decks.map((deck) => (
            <DeckCard
              onClick={() => setModalState(deck)}
              key={deck.id}
              deck={deck}
              type="public"
            />
          ))}
      </Flex>
      <Modal isOpen={!!modalState} onClose={() => setModalState(null)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Load a copy of this deck into the deck editor?
          </ModalHeader>
          {modalState && (
            <>
              <ModalBody>
                <HStack>
                  <Heading size="sm">{modalState.name}</Heading>
                  <Badge>id {modalState.id.slice(-10)}</Badge>
                </HStack>
                <Text>By {modalState.profile.username}</Text>
                <Text>
                  Any unsaved deck currently in your deck editor will be lost
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  mr={3}
                  onClick={() => setModalState(null)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    loadDeck(modalState, true);
                    setModalState(null);
                  }}
                >
                  Load Deck
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default DecksScreen;
