// State for a deck

import { clamp } from "lodash";
import React, { useContext, useState } from "react";
import { Card, LeaderCard } from "./card";
import CardsList, { CardsByID, LeadersByID } from "./cards";
import supabase from "./db/supabase";

type DeckCard = { card: Card; quantity: number; variant: string };

type DeckBase = {
  name: string;
  cTime: Date;
  mTime: Date;
  leader?: LeaderCard;
  main: DeckCard[];
};

export type OnlineDeck = DeckBase & {
  status: DeckStatus;
  id: string; // The id in the database
};

export type OfflineDeck = DeckBase & {
  status: -1;
};

export type Deck = OnlineDeck | OfflineDeck;

const isOnlineDeck = (deck: Deck): deck is OnlineDeck => deck.status >= 0;

const deckToCreateDeck = (deck: Deck) => ({
  leader: deck.leader?.id || null,
  name: deck.name,
  description: "",
  main: deck.main.map(({ card, quantity, variant }) => ({
    id: card.id,
    q: quantity,
    v: variant,
  })),
});

const deckToEditDeck = (deck: OnlineDeck) => ({
  ...deckToCreateDeck(deck),
  status: deck.status,
  id: deck.id,
});

const findCardOrWarn = <T extends any>(
  cardId: string | null,
  record: Record<string, T>
): T | undefined => {
  if (typeof cardId !== "string") {
    return undefined;
  }
  const possible = record[cardId];
  if (!possible) {
    console.warn(`Card id ${cardId} wasnt valid for saved deck`);
    return undefined;
  }
  return possible;
};

const isNotNull = <T extends any>(val: T | null): val is T =>
  val !== null ? true : false;

// Validate as we go
export const savedDeckToDeck = (saved: any): OnlineDeck => {
  const savedStatus = saved.status;
  const status = isValidStatus(savedStatus) ? savedStatus : 0;
  const name = saved.name || "Deck ???";
  const cTime = new Date(saved.ctime);
  const mTime = new Date(saved.mtime);

  // Find cards
  const leader = findCardOrWarn(saved.leader, LeadersByID);
  const main: DeckCard[] = Array.isArray(saved.main)
    ? (saved.main as any[])
        .map((item: any) => {
          if (typeof item !== "object") {
            console.warn(item, " is not an object");
            return null;
          }

          const card = findCardOrWarn(item.id, CardsByID);
          if (!card) {
            return null;
          }
          const quantity = typeof item.q === "number" ? item.q : 0;
          const variant = typeof item.v === "string" ? item.v : "base";

          return {
            card,
            quantity,
            variant,
          };
        })
        .filter(isNotNull)
    : [];

  return {
    id: saved.id,
    status,
    name,
    cTime,
    mTime,
    main,
    leader,
  };
};

/**
 * Deck statuses
 * 0 - Private
 * 1 - Unlisted
 * 2 - Public
 */
type DeckStatus = 0 | 1 | 2;
const isValidStatus = (status: number): status is DeckStatus =>
  [0, 1, 2].includes(status);

const createBlankDeck = (): Deck => ({
  name: "New Deck",
  leader: undefined,
  main: [],
  cTime: new Date(),
  mTime: new Date(),
  status: -1,
});

type DeckState = {
  deck: Deck;
  loadDeck: (deck: OnlineDeck, asCopy: boolean) => void;
  clearDeck: () => void;
  quantityByID: (cardID: string) => number;
  addCard: (card: Card, quantity?: number) => void;
  removeCard: (card: Card, quantity?: number) => void;
  createExport: () => string;
  importFromText: (text: string) => void;
  deckSize: () => number;
  setDeckName: (name: string) => void;
  changeStatus: (status: number) => void;
  saveDeck: () => Promise<void>;
  status: Status;
  touched: boolean;
};

const DeckContext = React.createContext<DeckState>(
  null as unknown as DeckState
); // Its probably gonna be always inited properly lol

type Status = "loading" | "ready";

export const DeckProvider = ({ children }: { children: React.ReactNode }) => {
  const [deck, setDeck] = useState(createBlankDeck);
  const [touched, setTouched] = useState(false);
  const [status, setStatus] = useState<Status>("ready");

  // Getters

  const quantityByID = (cardID: string) =>
    deck.main
      .filter(({ card }) => card.id === cardID)
      .reduce(
        (acc, { quantity }) => acc + quantity,
        deck.leader?.id === cardID ? 1 : 0
      );
  const addCard = (card: Card, num: number = 1) => {
    if (card.type === "leader") {
      setDeck({ ...deck, leader: card });
      return;
    }
    const copyOfDeck = {
      ...deck,
      leader: deck.leader,
      main: [...deck.main],
    };
    const existingCard = copyOfDeck.main.find((c) => c.card.id === card.id);
    if (!existingCard) {
      copyOfDeck.main.push({ card, quantity: num, variant: "base" });
    } else {
      existingCard.quantity += num;
    }
    setDeck(copyOfDeck);
  };

  const deckSize = () =>
    deck.main.reduce((acc, { quantity }) => acc + quantity, 0);

  const createExport = () => {
    const cards = [...deck.main];
    if (deck.leader) {
      cards.unshift({ card: deck.leader, quantity: 1, variant: "base" });
    }
    return `// One Piece TCG\n${cards
      .map(({ card, quantity }) => `${quantity} ${card.id} ${card.name}`)
      .join("\n")}`;
  };

  // Setters

  const setDeckName = (name: string) => {
    setTouched(true);
    setDeck({ ...deck, name });
  };

  const loadDeck = (deck: OnlineDeck, asCopy: boolean) => {
    setTouched(false);
    if (asCopy) {
      const copyOfDeck: OfflineDeck = {
        ...deck,
        cTime: new Date(),
        mTime: new Date(),
        status: -1,
        name: `Copy of ${deck.name}`,
      };
      setDeck(copyOfDeck);
    } else {
      setDeck(deck);
    }
  };

  const clearDeck = () => {
    setDeck(createBlankDeck());
    setTouched(false);
  };

  const removeCard = (card: Card, num: number = 1) => {
    setTouched(true);
    if (card.type === "leader") {
      setDeck({ ...deck, leader: undefined });
      return;
    }
    const copyOfDeck = {
      ...deck,
      leader: deck.leader,
      main: [...deck.main],
    };
    const idx = copyOfDeck.main.findIndex((c) => c.card.id === card.id);
    const existingCard = copyOfDeck.main[idx];
    if (existingCard) {
      existingCard.quantity -= num;
      if (existingCard.quantity <= 0) {
        copyOfDeck.main.splice(idx, 1);
      }
      setDeck(copyOfDeck);
    }
  };

  const updateDeckFromDB = async (id: string) => {
    const res = await supabase.from("deck").select("*").eq("id", id).single();
    if (res.error) {
      alert("Failed to fetch deck from server");
    } else {
      setDeck(savedDeckToDeck(res.data));
    }
  };

  const saveDeck = async () => {
    if (supabase.auth.user === null) {
      console.error("User not signed in");
      return;
    }
    setStatus("loading");
    if (deck.status === -1) {
      // Deck has not yet been saved
      const cdRes = await supabase.rpc("create_deck", deckToCreateDeck(deck));
      if (cdRes.error) {
        setStatus("ready");
        alert("Failed to save deck - sorry!");
      } else {
        if (cdRes.data) {
          console.log("Updating deck with what came back");
          await updateDeckFromDB(cdRes.data as unknown as string);
        }
        setTouched(false);
        setStatus("ready");
      }
    } else {
      // update deck
      const edRes = await supabase.rpc("edit_deck", deckToEditDeck(deck));
      if (edRes.error) {
        setStatus("ready");
        alert("Failed to save deck - sorry!");
      } else {
        console.log("Updating deck with what came back");
        await updateDeckFromDB(deck.id);
        setTouched(false);
        setStatus("ready");
      }
    }
  };

  const changeStatus = (status: number) => {
    setTouched(true);
    setDeck((prevDeck) => {
      if (isValidStatus(status) && isOnlineDeck(prevDeck)) {
        return { ...prevDeck, status };
      } else {
        return prevDeck;
      }
    });
  };

  const importFromText = (text: string) => {
    const importLineRegex = /(\d+) ([a-zA-Z0-9-]+).+$/;
    const main: DeckCard[] = [];
    let leader: Card | undefined = undefined;
    text.split("\n").forEach((line) => {
      const result = importLineRegex.exec(line);
      if (result) {
        const id = result[2];
        const quantity = result[1];
        if (result.length >= 3 && id && quantity) {
          const card = CardsByID[id];
          if (card) {
            if (card.type === "leader") {
              leader = leader ?? card;
            } else {
              main.push({ card, quantity: +quantity, variant: "base" });
            }
          }
        }
      }
    });
    setTouched(true);
    setDeck({
      main,
      leader,
      name: "Imported Deck",
      cTime: new Date(),
      mTime: new Date(),
      status: -1,
    });
  };

  return (
    <DeckContext.Provider
      value={{
        loadDeck,
        deck,
        clearDeck,
        quantityByID,
        addCard,
        removeCard,
        deckSize,
        createExport,
        setDeckName,
        saveDeck,
        status,
        changeStatus,
        importFromText,
        touched,
      }}
    >
      {children}
    </DeckContext.Provider>
  );
};

export const useDeck = () => useContext(DeckContext);
