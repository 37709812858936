import { ModalBody, ModalCloseButton, ModalHeader } from "@chakra-ui/react";
import SignIn from "./SignIn";

const AuthModal = () => {
  return (
    <>
      <ModalHeader>Sign In</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <SignIn />
      </ModalBody>
    </>
  );
};

export default AuthModal;
